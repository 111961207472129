/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import theme, { mediaquery } from "../styles/theme";
import adaptiveCssValue from "../helpers/adaptiveCssValue";
import { Link } from "gatsby";
import { Headline } from "./text";

const staggerTime = 60,
    animTime = 350;

const Veil = styled.div(
    css`
        position: fixed;
        right: calc(-150vmax + ${theme.fluidGrid.mdToX5l.margin} + ${24 - 8}px);
        top: calc(-150vmax + ${theme.fluidGrid.mdToX5l.margin} + ${24 - 8}px);

        display: block;
        width: 300vmax;
        height: 300vmax;
        z-index: 99991;
        background: ${theme.colors.spot1};
        opacity: 1;
        border-radius: 150vmax;
        transform-origin: center;
        transition: transform 0.5s, opacity 0.5s;

        ${mediaquery(
            "lg",
            `
            right: calc(-150vmax + ${theme.fluidGrid.mdToX5l.margin} + ${32 -
                8}px);
            top: calc(-150vmax + ${theme.fluidGrid.mdToX5l.margin} + ${32 -
                8}px);
        `
        )}
    `,
    props =>
        props.hidden
            ? css`
                  transition: transform 0.5s 0.1s, opacity 0.5s 0.1s;
                  transform: scale3d(0, 0, 1);
                  opacity: 0.4;
              `
            : null
);

const MenuWrapper = styled.nav(
    css`
        position: fixed;
        z-index: 99995;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        overflow-y: scroll;
        overscroll-behavior-y: contain;
        -webkit-overflow-scrolling: touch;
    `,
    props => {
        return css`
            transition: transform 0s linear
                ${props.hidden
                    ? props.items.length * staggerTime + animTime
                    : "0"}ms;
            transform: translateX(${props.hidden ? "-120vw" : "0"});
        `;
    }
);

const ItemList = styled.ul(
    css`
        flex-grow: 1;
        list-style: none;
        margin: 0;
        padding: 0;
        transform: translateY(-3vw);
    `
);

const Item = styled.li(
    css`
        display: block;
        margin: 0;
        padding: 0;
        transition: all ${animTime}ms cubic-bezier(0.3, 1, 0.3, 1);
    `,
    props =>
        `transform: translateY(${props.hidden ? -4 : 0}em); opacity: ${
            props.hidden ? 0.001 : 1
        };`,
    props => {
        return `transition-delay: ${staggerTime * props.staggerIndex}ms;`;
    }
);

const A = styled(Headline)`
    display: block;
    color: inherit;
    text-decoration: none;
    padding: 0.4em 0;
    &::before {
        content: "";
        display: inline-block;
        width: ${adaptiveCssValue(
            theme.breakpointByAlias["md"],
            theme.breakpointByAlias["x5l"],
            13.35,
            85.2
        )};
    }

    > span {
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 40%;
            background-color: ${theme.colors.background};
            z-index: -1;
            transform: scale3d(1, 0, 1);
            transform-origin: center bottom;
            transition: transform 0.25s ease-out;
            backface-visibility: hidden;
        }
    }

    &:hover {
        > span::after {
            transform: scale3d(1, 1, 1);
        }
    }
`.withComponent(Link);

const Menu = ({ hidden, items, onclick }) => (
    <React.Fragment>
        <Veil hidden={hidden} />
        <MenuWrapper hidden={hidden} items={items}>
            <ItemList>
                {items.map((thisItem, index) => (
                    <Item
                        key={thisItem.title + index}
                        hidden={hidden}
                        staggerIndex={index}
                    >
                        <A to={thisItem.href} onClick={onclick}>
                            <span>{thisItem.title}</span>
                        </A>
                    </Item>
                ))}
            </ItemList>
        </MenuWrapper>
    </React.Fragment>
);

Menu.propTypes = {
    hidden: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onclick: PropTypes.func
};
Menu.defaultProps = {
    hidden: true
};

export default Menu;
